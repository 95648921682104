import { Box, Snackbar, Alert } from "@mui/material"
import { Suspense, lazy, useContext, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { ToolContext } from "../../../../core/context/ToolContext"
import { env, domain } from "../../../../core/Env"
import { rqx } from "../../../../core/request/API"
import { pslstyle } from "../../../../core/styling/si"
import { validatePassword } from "../../../../core/validator/Validator"
import { SnakeLoader } from "../../../../core/loader/SnakeLoader";
import LLogo from '../../../../assets/images/logos/pofsis_no_border.png'
import { VM } from "../../../../core/VM";
const device = VM();
const Auth = lazy(() => import(`.${device}/Auth`))

const __SESSION = JSON.parse(localStorage.getItem('pm_tool_session'))

export const Finalize = ({ match }) => {
    const { switching_state } = useContext(ToolContext)
    const { step, sainfo, inputs, exist, submitted } = switching_state.finalize.data
    const platform = match.params.platform
    const classes = pslstyle(platform)
    const q = new URLSearchParams(useLocation().search);
    const history = useHistory()

    useEffect(() => {
        let s = JSON.parse(q.get('sainfo'))
        const __init = async () => {
            let check = await rqx.get(`<<tool>>/${env()}/${platform}/si/attempt/${s.va}`, {account: window.atob(s.account).split('_').pop(), ref: 1, platform: platform})
            if (check.msg === '!valid') {
                switching_state.finalize.set({...switching_state.finalize.data, exist: false, sainfo: s})
            } else {
                switching_state.finalize.set({...switching_state.finalize.data, exist: true, sainfo: s})
            }
        }
        __init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const inputHandler = (e) => {
        const {name, value} = e.target
        let vp = validatePassword(e.target.value)
        let inps = {...inputs}
        inps[name].value = value
        inps[name].stat = false
        inps[name].msg = ''
        inps[name].strength = vp.svalidator
        switching_state.finalize.set({...switching_state.finalize.data, validated: vp.validated, inputs: inps})
    }

    const visibilityHandler = () => {
        let inps = {...inputs}
        inps.password.toggle = !inps.password.toggle
        switching_state.finalize.set({...switching_state.finalize.data, inputs: inps})
    }

    const enterHandler = (event) => { return event.key === 'Enter' ? submitHandler() : ''}
    const submitHandler = async () => {
        if (step!==10 && step!==20) {
            if (inputs.password.value !== '') {
                let vp = validatePassword(inputs.password.value)
                if (vp.wvalidator === 0) {
                    switching_state.finalize.set({...switching_state.finalize.data, step: 10})
                    let attempt = await rqx.post(`<<tool>>/${env()}/${platform}/su/attempt/${sainfo.va}`, {account: window.atob(sainfo.account).split('unauth_').pop(), password: inputs.password.value, ref: '2', parent_id: sainfo.aid})
                    switching_state.finalize.set({...switching_state.finalize.data, btn: 'Redirecting', submitted: true})
                    let url = platform==='psl'?domain('psl').url:domain('biz').url
                    if (q.get('from') !== null ) {
                        let subdir = `ainfo=${encodeURIComponent(JSON.stringify(attempt.ainfo))}`
                        if (__SESSION.tsa_key === 'TOOL_MARKET_PREVIEW_SSO_RQX') {
                            subdir += `&market_preview=${__SESSION.tid}&platform=${__SESSION.platform}`
                        } else if (__SESSION.tsa_key === 'MP_MARKET_CHECKOUT_SSO_RQX') {
                            subdir += `&platform=${__SESSION.platform}&tid=${__SESSION.tid}&pricing=${encodeURIComponent(__SESSION.pricing)}`
                        } else if (__SESSION.tsa_key === 'MP_DIRECT_CHECKOUT_RQX') {
                            subdir += `&platform=${__SESSION.platform}&tid=${__SESSION.tid}&pricing=${encodeURIComponent(__SESSION.pricing)}&ref=auth&subcb=${__SESSION.subcb}`
                        }

                        url = `${__SESSION.cb}?${subdir}`
                    } else {
                        url = `${url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(attempt.ainfo))}`
                    }

                    setTimeout(() => {
                        window.location.href = url
                    }, 1500);
                } else {
                    // setInputs({...inputs, password : {...inputs.password, stat: true, msg: 'Minimum of 6 characters.'}})
                }
            } else {
                // setInputs({...inputs, password : {...inputs.password, stat: true, msg: 'Minimum of 6 characters.'}})
            }
        }
    }

    
    const loginHandler = () => {
        history.push(`/${platform}/login`)
    }

    return exist !== null ? (
        <Suspense fallback="">
            <Box height="100%">
                <Auth platform={platform} classes={classes} {...switching_state.finalize.data} inputHandler={inputHandler} enterHandler={enterHandler} submitHandler={submitHandler} q={q} visibilityHandler={visibilityHandler} loginHandler={loginHandler} />
                <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={submitted} 
                        // autoHideDuration={6000} 
                        // onClose={handleClose}
                    >
                    <Alert variant="filled" severity="success">Account successfully registered</Alert>
                </Snackbar>
            </Box>
        </Suspense>
    ) : (
        <Box height="100vh" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <img src={LLogo} width={50} alt={LLogo} />
            <Box mt={4}>
                <SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
            </Box>
        </Box>
    )
}