import { Box } from "@mui/system";
import { Button, Divider, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import { RemoveRedEyeOutlined as IRemoveRedEye , VisibilityOffOutlined as IVisibilityOff } from "@mui/icons-material";
import { SnakeLoader } from "../../../core/loader/SnakeLoader";
// import IPslAccount from '../../../assets/images/icons/psl_account.png'
// import IBizEmail from '../../../assets/images/icons/biz_email.png'
// import IPslLock from '../../../assets/images/icons/lock1.png'
// import IBizLock from '../../../assets/images/icons/lock2.png'
import GSPFSS from '../../../assets/images/logos/pofsis_grayscale.jpg'
import LPFSS from '../../../assets/images/logos/pofsis_no_border.png'
import LPSL from '../../../assets/images/logos/psl_logo.png'
import LBIZ from '../../../assets/images/logos/biz_logo.png'
import { VM } from "../../../core/VM";
import NavigationV2 from "../../layouts/navigations_v2/Index";
let view = VM()

const Auth = ({ classes, platform, exist, sainfo, inputs, step, btn, validated, inputHandler, enterHandler, submitHandler, visibilityHandler, q, loginHandler, __SESSION }) => {
    return view === '/devices/mobile' ? (
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" height="100%" bgcolor="#FFF">
            <NavigationV2 side={0} />
            <Box borderRadius={4} p={4} width="100%" height="100%" display="flex" flexDirection="column" justifyContent="space-between" >
                <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" width="100%">
                    <Box mt={3} fontSize="20px" fontWeight="700" align="center"  color="#101828">Set up Password</Box>
                    <Box width="100%" mt={3}>
                        <Box display="flex" justifyContent="center" mb={3}>
                            <Box fontSize={{xs:14, sm:14}} fontWeight="600" textAlign="center" color={platform==='psl'?'primary.personal':'primary.business'}>{sainfo.va==='mobile_num'?'+63 ': ''} <b>{window.atob(sainfo.account).split('unauth_').pop()}</b></Box>
                        </Box>
                        {
                            !exist ? (
                                <Box>
                                    <Box display="flex" fontSize="12px">Password <Box color="red" ml={.5} mb={1}> *</Box></Box>
                                    <TextField size="small" variant="outlined" type={inputs.password.toggle ? 'text': 'password'} name="password" value={inputs.password.value} onInput={(e)=>inputHandler(e, 'pass')} error={inputs.password.stat} fullWidth className={`${classes.tf} ${classes.border}`} onKeyPress={enterHandler}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={(e)=>visibilityHandler('pass')}>
                                                        {inputs.password.toggle ? <IRemoveRedEye /> : <IVisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />

                                    <Box display="flex" mt={1.5}  fontSize="12px">Re enter password <Box color="red" ml={.5} mb={1}> *</Box></Box>
                                    <TextField size="small" variant="outlined" type={inputs.repass.toggle ? 'text': 'password'} name="repass" value={inputs.repass.value} onInput={(e)=>inputHandler(e, 'repass')} error={inputs.repass.stat} fullWidth className={`${classes.tf} ${classes.border}`} onKeyPress={enterHandler}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={(e)=>visibilityHandler('repass')}>
                                                        {inputs.repass.toggle ? <IRemoveRedEye /> : <IVisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    
                                    <Box mt={1.5}>
                                        <Box mt={1.5} color="#6A6A6A" fontSize='12px'>
                                            <Typography variant="body2" component="div" style={{ whiteSpace: 'normal', wordWrap: 'break-word' , maxWidth: '425px', fontSize: '12px'}}>
                                                {!validated.length && "Minimum of 8 characters,"}
                                                {!validated.lc && "Lower case,"}
                                                {!validated.uc && "Upper case,"}
                                                {!validated.number && "Number (123),"}
                                                {!validated.sc && "Symbol (e.g., @*#-)"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                            ) : (
                                <Box textAlign="center">
                                    <Box>This account has already been registered.</Box>
                                </Box>
                            )
                        }
                    </Box>
                    <Box mt="24px" width="100%">
                        {
                            !exist ? (
                                <Box>
                                    <Box fontSize={{xs:12, sm:12}} mb={4} textAlign="center">
                                    By clicking submit, you agree with our <Link pl={0.5} style={{ color: platform==='psl'? '#0070ef' : '#11783C', textDecoration: 'underline' }} to="/termsandconditions" target="_blank">Terms and conditions</Link>,<br></br> <Link pl={0.5} style={{ color: platform==='psl'? '#0070ef' : '#11783C', textDecoration: 'underline'  }} to="/dataprivacypolicy" target="_blank">Data privacy policy</Link> and <Link pl={0.5} style={{ color: platform==='psl'? '#0070ef' : '#11783C', textDecoration: 'underline'  }} to="/cookiepolicy" target="_blank">Cookie policy</Link>
                                    </Box>
                                    <Box>
                                        <Button variant="contained" fullWidth className={`${classes.button} ${classes.bgpalette}`} onClick={btn === 'Redirecting' ? null : submitHandler} disabled={inputs.password.strength!==0?true:false}>
                                            {
                                                step===1?(
                                                    <Box display="flex" alignItems="center">{btn}</Box>
                                                ) : (
                                                    btn==='Submit'?(
                                                        <Box display="flex" alignItems="center" p={0.9}> <SnakeLoader bg="#ffffff" size="0.75rem" distance="0.3rem" /> </Box>
                                                    ) : (
                                                        <Box display="flex" alignItems="center">{btn}</Box>
                                                    )
                                                )
                                            }
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <Button variant="contained" fullWidth className={`${classes.button} ${classes.bgpalette}`} onClick={loginHandler}>Go to login</Button>
                            )
                        }
                    </Box>
                </Box>
                <Box>
                    <Box mb={3} mt={3}><Divider sx={{ height: '100%'}} /></Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Box display="flex" alignItems="center" >
                            <img src={GSPFSS} alt={GSPFSS} width="14" />
                            <Box fontSize={14} fontWeight="600" color="#333333" ml={1}>POFSIS</Box>
                        </Box>
                        <Box color="#5B6167" fontSize="11px" mb={-1}>One account for Personal , Business and more.</Box>
                    </Box>
                </Box>
            </Box>
        </Box>

        )
    
        :(
        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" height="100%">
        <Box />
            <Box borderRadius={5} p={3}>
            <Box boxShadow="0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)" bgcolor="#FFF"  display="flex" flexDirection="column" alignItems="center" justifyContent="center" borderRadius={5} px={4} py={5} width={'auto'} height={'auto'} minWidth="480px">
            {/* <Box p={3} borderRadius={5} bgcolor="secondary.light" className={`shadow`} width={{xs:'290px', sm:'458px'}} height={{xs:'560px', sm:'592px'}}> */}
                {/* <Box><img src={platform==='psl'?IPslLock:IBizLock} alt="ILock" /></Box> */}
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <Box p={1} display="flex" alignItems="center"><img src={LPFSS} alt="POFSIS Logo" width="26" /></Box>
                    {
                        q.get('from') !== null ? (
                            <Box className="personal-logo-px-1" ml={3} sx={{backgroundImage: `url(${JSON.parse(__SESSION.tinfo[0].logo)})`}} />
                        ) : <Box className="personal-logo-px-1" ml={3} sx={{backgroundImage: `url(${platform==='psl'?LPSL:LBIZ})`}} />
                    }
                </Box>
                <Box mt={3} fontSize="30px" fontWeight="600" align="center"  color="#101828">Set up Password</Box>
                <Box width="100%" mt={4}>
                    <Box display="flex" justifyContent="center" mb={4}>
                        <Box fontSize={{xs:14, sm:16}} fontWeight="700" textAlign="center" color={platform==='psl'?'primary.personal':'primary.business'}>{sainfo.va==='mobile_num'?'+63 ': ''} <b>{window.atob(sainfo.account).split('unauth_').pop()}</b></Box>
                    </Box>
                    {
                        !exist ? (
                            <Box>
                                <Box display="flex">Password <Box color="red" ml={.5} mb={1}> *</Box></Box>
                                <TextField size="small" variant="outlined" type={inputs.password.toggle ? 'text': 'password'} name="password" value={inputs.password.value} onInput={(e)=>inputHandler(e, 'pass')} error={inputs.password.stat} fullWidth className={`${classes.tf} ${classes.border}`} onKeyPress={enterHandler}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={(e)=>visibilityHandler('pass')}>
                                                    {inputs.password.toggle ? <IRemoveRedEye /> : <IVisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <Box display="flex" mt={3}>Re enter password <Box color="red" ml={.5} mb={1}> *</Box></Box>
                                <TextField size="small" variant="outlined" type={inputs.repass.toggle ? 'text': 'password'} name="repass" value={inputs.repass.value} onInput={(e)=>inputHandler(e, 'repass')} error={inputs.repass.stat} fullWidth className={`${classes.tf} ${classes.border}`} onKeyPress={enterHandler}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={(e)=>visibilityHandler('repass')}>
                                                    {inputs.repass.toggle ? <IRemoveRedEye /> : <IVisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                
                                <Box mt={3}>
                                    <Box mt={1.5} color="#6A6A6A">
                                        <Typography variant="body2" component="div" style={{ whiteSpace: 'normal', wordWrap: 'break-word' , maxWidth: '425px'}}>
                                            {!validated.length && "Minimum of 8 characters,"}
                                            {!validated.lc && "Lower case,"}
                                            {!validated.uc && "Upper case,"}
                                            {!validated.number && "Number (123),"}
                                            {!validated.sc && "Symbol (e.g., @*#-)"}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                        ) : (
                            <Box textAlign="center">
                                <Box>This account has already been registered.</Box>
                            </Box>
                        )
                    }
                </Box>
                <Box mt="32px" width="100%">
                    {
                        !exist ? (
                            <Box>
                                
                                <Box fontSize={{xs:12, sm:14}} mb={4} textAlign="center">
                                By clicking submit, you agree with our <Link pl={0.5} style={{ color: platform==='psl'? '#0070ef' : '#11783C', textDecoration: 'underline' }} to="/termsandconditions" target="_blank">Terms and conditions</Link>,<br></br> <Link pl={0.5} style={{ color: platform==='psl'? '#0070ef' : '#11783C', textDecoration: 'underline'  }} to="/dataprivacypolicy" target="_blank">Data privacy policy</Link> and <Link pl={0.5} style={{ color: platform==='psl'? '#0070ef' : '#11783C', textDecoration: 'underline'  }} to="/cookiepolicy" target="_blank">Cookie policy</Link>
                                </Box>
                                {/* added btn === 'Redirecting' for validation on multiple clist on button during loading */}
                                <Box>
                                    <Button variant="contained" fullWidth className={`${classes.button} ${classes.bgpalette}`} onClick={btn === 'Redirecting' ? null : submitHandler} disabled={inputs.password.strength!==0?true:false}>
                                        {
                                            step===1?(
                                                <Box display="flex" alignItems="center">{btn}</Box>
                                            ) : (
                                                btn==='Submit'?(
                                                    <Box display="flex" alignItems="center" p={0.9}> <SnakeLoader bg="#ffffff" size="0.75rem" distance="0.3rem" /> </Box>
                                                ) : (
                                                    <Box display="flex" alignItems="center">{btn}</Box>
                                                )
                                            )
                                        }
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Button variant="contained" fullWidth className={`${classes.button} ${classes.bgpalette}`} onClick={loginHandler}>Go to login</Button>
                        )
                    }
                </Box>
            </Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box display="flex" alignItems="center" >
                    <img src={GSPFSS} alt={GSPFSS} width="14" />
                    <Box fontSize={16} fontWeight="600" color="#333333" ml={1}>POFSIS</Box>
                </Box>
                <Box color="#5B6167" fontSize="12px" mb={3}>One account for Personal , Business and more.</Box>
            </Box>
        </Box>
    )
}

export default Auth;