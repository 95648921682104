import { Box } from "@mui/material"
import { VM } from "../../../core/VM"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
let view = VM()

export const Bottom = () => {
    return (
        <Box width="100%">
            {
                view === '/devices/mobile' ?
                <Box  p={3} width="100%" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" mt={10}>
                    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                        <Box display="flex" justifyContent="center" gap={3} fontSize="12px" fontWeight="400" >
                            <Link pl={0.5} style={{ color: '#292C2F', textDecoration: 'underline' }} to="/termsandconditions" target="_blank">
                                Terms of Use
                            </Link>
                            <Link pl={0.5} style={{ color: '#292C2F', textDecoration: 'underline'  }} to="/dataprivacypolicy" target="_blank">
                                Privacy Policy
                            </Link>
                            <Link pl={0.5} style={{ color: '#292C2F', textDecoration: 'underline'  }} to="/cookiepolicy" target="_blank">
                                Cookies Policy
                            </Link>
                        </Box>
                        <Box color="#5B6167" fontWeight="400" lineHeight="147%" fontSize="12px" mt={1}>
                            Pofsis © 2023
                        </Box>
                    </Box>
                </Box>
                :
                    <Box  p={3} width="100%" display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" mt={10}>
                        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                            <Box display="flex" justifyContent="center" gap={3} fontSize="14px" fontWeight="400" >
                                <Link pl={0.5} style={{ color: '#292C2F', textDecoration: 'underline' }} to="/termsandconditions" target="_blank">
                                    Terms of Use
                                </Link>
                                <Link pl={0.5} style={{ color: '#292C2F', textDecoration: 'underline'  }} to="/dataprivacypolicy" target="_blank">
                                    Privacy Policy
                                </Link>
                                <Link pl={0.5} style={{ color: '#292C2F', textDecoration: 'underline'  }} to="/cookiepolicy" target="_blank">
                                    Cookies Policy
                                </Link>
                            </Box>
                            <Box color="#5B6167" fontWeight="400" lineHeight="147%" fontSize="14px" mt={2}>
                                Pofsis © 2023
                            </Box>
                        </Box>
                    </Box>
            }   
        </Box>
    )
}