import { Box, Snackbar, Alert } from "@mui/material"
import { useContext, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { ToolContext } from "../../../core/context/ToolContext"
import { env } from "../../../core/Env"
import { rqx } from "../../../core/request/API"
import { pslstyle } from "../../../core/styling/si"
import { validatePassword } from "../../../core/validator/Validator"
import { SnakeLoader } from "../../../core/loader/SnakeLoader";
import LLogo from '../../../assets/images/logos/pofsis_no_border.png'
import Auth from "./Auth"

export const Finalize = ({ match }) => {
    const { reset_password_state } = useContext(ToolContext)
    const { step, sainfo, inputs, exist, submitted } = reset_password_state.finalize.data
    const platform = match.params.platform
    const classes = pslstyle(platform)
    const q = new URLSearchParams(useLocation().search);
    const history = useHistory()

    useEffect(() => {
        let s = JSON.parse(q.get('sainfo'))
        reset_password_state.finalize.set({...reset_password_state.finalize.data, exist: false, sainfo: s})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const inputHandler = (e) => {
        const {name, value} = e.target
        let vp = validatePassword(e.target.value)
        let inps = {...inputs}
        inps[name].value = value
        inps[name].stat = false
        inps[name].msg = ''
        inps[name].strength = vp.svalidator
        reset_password_state.finalize.set({...reset_password_state.finalize.data, validated: vp.validated, inputs: inps})
    }

    const visibilityHandler = () => {
        let inps = {...inputs}
        inps.password.toggle = !inps.password.toggle
        reset_password_state.finalize.set({...reset_password_state.finalize.data, inputs: inps})
    }

    const enterHandler = (event) => { return event.key === 'Enter' ? submitHandler() : ''}
    const submitHandler = async () => {
        if (step!==10 && step!==20) {
            if (inputs.password.value !== '') {
                let vp = validatePassword(inputs.password.value)
                if (vp.wvalidator === 0) {
                    reset_password_state.finalize.set({...reset_password_state.finalize.data, step: 10})
                    let attempt = await rqx.post(`<<tool>>/${env()}/${platform}/resetpass/attempt/${sainfo.va}`, {account: window.atob(sainfo.default).split('_').pop(), password: inputs.password.value, aid: sainfo.aid, ref: '2'})
                    
                    if (attempt.msg === 'success') {
                        reset_password_state.finalize.set({...reset_password_state.finalize.data, btn: 'Redirecting', submitted: true})
                        let url = `/${platform}`
                        if (sainfo.rrf === 'switching1') {
                            let s = {
                                va: sainfo.va,
                                account: sainfo.default,
                                step: 2,
                                srf: sainfo.srf
                            }
                            url += `/switching/option1?${q.get('from')!==null?`from=${encodeURIComponent(q.get('from'))}&`:''}sainfo=${encodeURIComponent(JSON.stringify(s))}`
                        } else if (sainfo.rrf === 'switching2') {
                            let s = {
                                va: sainfo.va,
                                aid: sainfo.paid
                            }
                            url += `/switching/option2?${q.get('from')!==null?`from=${encodeURIComponent(q.get('from'))}&`:''}sainfo=${encodeURIComponent(JSON.stringify(s))}`
                        } else if (sainfo.rrf === 'tool_login') {
                            url += `/tool/login${q.get('from')!==null?`?from=${encodeURIComponent(q.get('from'))}&`:''}`
                        } else {
                            url += `/login${q.get('from')!==null?`?from=${encodeURIComponent(q.get('from'))}&`:''}`
                        }

                        setTimeout(() => {
                            window.location.href = url
                        }, 1500);
                    }
                } else {
                    // setInputs({...inputs, password : {...inputs.password, stat: true, msg: 'Minimum of 6 characters.'}})
                }
            } else {
                // setInputs({...inputs, password : {...inputs.password, stat: true, msg: 'Minimum of 6 characters.'}})
            }
        }
    }

    
    const loginHandler = () => {
        history.push(`/${platform}/login`)
    }

    return exist !== null ? (
        <Box height="100%">
            <Auth platform={platform} classes={classes} {...reset_password_state.finalize.data} inputHandler={inputHandler} enterHandler={enterHandler} submitHandler={submitHandler} q={q} visibilityHandler={visibilityHandler} loginHandler={loginHandler} />
            <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={submitted} 
                    // autoHideDuration={6000} 
                    // onClose={handleClose}
                >
                <Alert variant="filled" severity="success">Password successfully updated</Alert>
            </Snackbar>
        </Box>
    ) : (
        <Box height="100vh" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <img src={LLogo} width={50} alt={LLogo} />
            <Box mt={4}>
                <SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
            </Box>
        </Box>
    )
}