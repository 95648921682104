import { Box, Snackbar, Alert } from "@mui/material"
import { useContext, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { ToolContext } from "../../../core/context/ToolContext"
import { env, domain } from "../../../core/Env"
import { rqx } from "../../../core/request/API"
import { pslstyle } from "../../../core/styling/si"
import { validatePassword } from "../../../core/validator/Validator"
import { SnakeLoader } from "../../../core/loader/SnakeLoader";
import LLogo from '../../../assets/images/logos/pofsis_no_border.png'
import PFSHPLOG from '../../../assets/images/logos/pfshp_logo.png'
import Auth from "./Auth"



export const Finalize = ({ match }) => {
    const __SESSION = JSON.parse(localStorage.getItem('pm_tool_session'))
    const { su_state } = useContext(ToolContext)
    const { step, sainfo, inputs, exist, submitted } = su_state.finalize.data
    const platform = match.params.platform
    const classes = pslstyle(platform)
    const q = new URLSearchParams(useLocation().search);
    const history = useHistory()

    useEffect(() => {
        let s = JSON.parse(q.get('sainfo'))
        let checkDirect = q.get('directReg')
        const __init = async () => {
            let check = await rqx.get(`<<tool>>/${env()}/${platform}/si/attempt/${s.va}`, {account: window.atob(s.account).split('_').pop(), ref: 1, platform: platform})
            if (check.msg === '!valid') {
                if (checkDirect === '1') {
                    let ab = JSON.parse(q.get('from'))
                    let strg = {cb:ab.cb, tid: ab.tid, tinfo: ab.tinfo, expiration: '', platform: ab.platform, tsa_key: ab.tsa_key, pricing: ab.pricing, subcb: ab.subcb, ref_a_frnd: ab.ref_a_frnd}
                    localStorage.setItem('pm_tool_session', JSON.stringify(strg))
                }
                su_state.finalize.set({...su_state.finalize.data, exist: false, sainfo: s})
            } else {
                if (checkDirect === '1') {
                    localStorage.removeItem('pm_tool_session')
                    let ab = JSON.parse(q.get('from'))
                    if (ab.tsa_key === "PFSHP_SSO_RQX") {
                        ab.tinfo[0].logo = JSON.stringify(PFSHPLOG)
                    }
                    let strg = {cb:ab.cb, tid: ab.tid, tinfo: ab.tinfo, expiration: '', platform: ab.platform, tsa_key: ab.tsa_key, pricing: ab.pricing, subcb: ab.subcb, ref_a_frnd: ab.ref_a_frnd}
                    localStorage.setItem('pm_tool_session', JSON.stringify(strg))
                }
                su_state.finalize.set({...su_state.finalize.data, exist: true, sainfo: s})
            }
        }
        __init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const inputHandler = (e, ref) => {
        if (ref === 'repass') {
            const {name, value} = e.target
            let vp = validatePassword(e.target.value)
            let inps = {...inputs}
            inps[name].value = value
            inps[name].stat = false
            inps[name].msg = ''
            inps[name].strength = vp.svalidator
            su_state.finalize.set({...su_state.finalize.data, validated: vp.validated, inputs: inps})
        } else {
            const {name, value} = e.target
            let vp = validatePassword(e.target.value)
            let inps = {...inputs}
            inps[name].value = value
            inps[name].stat = false
            inps[name].msg = ''
            inps[name].strength = vp.svalidator
            su_state.finalize.set({...su_state.finalize.data, validated: vp.validated, inputs: inps})
        }
    }

    const visibilityHandler = (ref) => {
        if (ref === 'repass') {
            let inps = {...inputs}
            inps.repass.toggle = !inps.repass.toggle
            su_state.finalize.set({...su_state.finalize.data, inputs: inps})
        } else {
            let inps = {...inputs}
            inps.password.toggle = !inps.password.toggle
            su_state.finalize.set({...su_state.finalize.data, inputs: inps})
        }
    }

    const enterHandler = (event) => { return event.key === 'Enter' ? submitHandler() : ''}
    const submitHandler = async () => {
        if (step!==10 && step!==20) {
            if (inputs.password.value !== '' && inputs.repass.value !== '') {
                if (inputs.repass.value === inputs.password.value) {    
                    let vp = validatePassword(inputs.password.value)
                    if (vp.wvalidator === 0) {
                        su_state.finalize.set({...su_state.finalize.data, step: 10})
                        let attempt = await rqx.post(`<<tool>>/${env()}/${platform}/su/attempt/${sainfo.va}`, {account: window.atob(sainfo.account).split('unauth_').pop(), password: inputs.password.value, ref: '2', parent_id: 0})
                        su_state.finalize.set({...su_state.finalize.data, btn: 'Redirecting', submitted: true})
                        let url = platform==='psl'?domain('psl').url:domain('biz').url
                        if (q.get('from') !== null ) {
                            let subdir = `ainfo=${encodeURIComponent(JSON.stringify(attempt.ainfo))}`
                            if (__SESSION.tsa_key === 'TOOL_MARKET_PREVIEW_SSO_RQX') {
                                subdir += `&market_preview=${__SESSION.tid}&platform=${__SESSION.platform}`
                            } else if (__SESSION.tsa_key === 'MP_MARKET_CHECKOUT_SSO_RQX') {
                                subdir += `&platform=${__SESSION.platform}&tid=${__SESSION.tid}&pricing=${encodeURIComponent(__SESSION.pricing)}`
                            } else if (__SESSION.tsa_key === 'MP_DIRECT_CHECKOUT_RQX') {
                                subdir += `&platform=${__SESSION.platform}&tid=${__SESSION.tid}&pricing=${encodeURIComponent(__SESSION.pricing)}&ref=auth&subcb=${__SESSION.subcb}`
                            } else if (__SESSION.tsa_key === 'PFSHP_SSO_RQX' || __SESSION.tsa_key === 'PFSHP_SSO_DIRECT_RQX') {
                                if (__SESSION.ref_a_frnd !== null) {
                                    let refData = JSON.parse(window.atob((__SESSION.ref_a_frnd)))
                                    let refer = await rqx.post(`<<global>>/${env()}/referral/create`, {tool:refData.tid, referrer_user_id:refData.aid, referred_user_id:attempt.ainfo.aid, referred_from:"1"})
                                }
                                subdir += `&platform=${__SESSION.platform}&ref=auth&subcb=${__SESSION.subcb}`
                            } else if (__SESSION.tsa_key === 'PHRC_SSO_RQX' || __SESSION.tsa_key === 'PHRC_SSO_DIRECT_RQX') {
                                if (__SESSION.ref_a_frnd !== null) {
                                    let refData = JSON.parse(window.atob((__SESSION.ref_a_frnd)))
                                    let refer = await rqx.post(`<<global>>/${env()}/referral/create`, {tool:refData.tid, referrer_user_id:refData.aid, referred_user_id:attempt.ainfo.aid, referred_from:"1"})
                                }
                                subdir += `&platform=${__SESSION.platform}&ref=auth&subcb=${__SESSION.subcb}`
                            }
    
                            url = `${__SESSION.cb}?${subdir}&ref_a_frnd=${__SESSION.ref_a_frnd}`
                        } else {
                            let ref = q.get('ref_a_frnd')
                            if (ref !== null) {
                                let refData = JSON.parse(window.atob(ref))
                                let data =  {tool:refData.tid, referrer_user_id:refData.aid, referred_user_id:attempt.ainfo.aid, referred_from:refData.ref_from}
                                // console.log(data)
                                let refer = await rqx.post(`<<global>>/${env()}/referral/create`,data)
                            }
                            url = `${url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(attempt.ainfo))}`
                        }
    
                        setTimeout(() => {
                            window.location.href = url
                        }, 1500);
                    } else {
                        // setInputs({...inputs, password : {...inputs.password, stat: true, msg: 'Minimum of 6 characters.'}})
                    }
                } else {
                    // setInputs({...inputs, repass : {...inputs.password, stat: true, msg: 'Not Equal passwords'}})
                    let inps = {...inputs}
                    inps['repass'].stat = true
                    inps['repass'].msg = 'Not Equal passwords'
                    su_state.finalize.set({...su_state.finalize.data, inputs: inps})
                } 
            }else {
                    // setInputs({...inputs, password : {...inputs.password, stat: true, msg: 'Minimum of 6 characters.'}})
            }
        }
    }
    

    
    const loginHandler = () => {
        history.push(`/${platform}/login${q.get('from').length > 0 ? `?from=${q.get('from')}` : ''}`)
    }

    return exist !== null ? (
        <Box height="100%">
            <Auth platform={platform} classes={classes} {...su_state.finalize.data} inputHandler={inputHandler} enterHandler={enterHandler} submitHandler={submitHandler} q={q} visibilityHandler={visibilityHandler} loginHandler={loginHandler} __SESSION={__SESSION}/>
            <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={submitted} 
                    // autoHideDuration={6000} 
                    // onClose={handleClose}
                >
                <Alert variant="filled" severity="success">Account successfully registered</Alert>
            </Snackbar>
        </Box>
    ) : (
        <Box height="100vh" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <img src={LLogo} width={50} alt={LLogo} />
            <Box mt={4}>
                <SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
            </Box>
        </Box>
    )
}