import { lazy, Suspense, useContext, useEffect } from "react"
import { Box, Snackbar, Alert } from "@mui/material";
import { VM } from "../../../core/VM"
import { optstyle, LogoAnimator } from "../../../core/styling/options"
import { ToolContext } from "../../../core/context/ToolContext";
import { validateAccount } from "../../../core/validator/Validator";
import { rqx } from '../../../core/request/API';
import { env, domain } from '../../../core/Env';
import { useHistory, useLocation } from 'react-router-dom';
import { SnakeLoader } from "../../../core/loader/SnakeLoader";
import LLogo from '../../../assets/images/logos/pofsis_no_border.png'

const device = VM();
const Auth = lazy(() => import(`.${device}/Auth`))
const __SESSION = JSON.parse(localStorage.getItem('pm_tool_session'))

export const Option1 = ({ match }) => {
    const { switching_state } = useContext(ToolContext)
    const { srf, stat, step, va, inputs, tpinputs } = switching_state
    const q = new URLSearchParams(useLocation().search);
    const sainfo = JSON.parse(q.get('sainfo'))
    const platform = match.params.platform
    const classes = optstyle(platform === 'psl' ? sainfo.srf === '!default' ? 'biz' : 'psl' : sainfo.srf === '!default' ? 'psl' : 'biz')
    const animator = LogoAnimator()
    const history = useHistory()

    useEffect(() => {
        const __init = () => {
            const sainfo = JSON.parse(q.get('sainfo'))
            if (sainfo !== null) {
                step.set(parseInt(sainfo.step))
                stat.set({linked: false, allowed: true})
                va.set(sainfo.va)
                srf.set(sainfo.srf)
                inputs.set({...inputs.data, account : {...inputs.data.account, value: window.atob(sainfo.account).split('unauth_').pop(), stat: false, msg: ''}})
                return
            }

            step.set('!sainfo')
            stat.set({linked: false, allowed: false})
        }

        __init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const backHandler = () => {
        if (step.data === 1) {
            history.goBack()
        } else if (step.data === 2) {
            // step.set(1)
            // inputs.set({...inputs.data, account: {...inputs.data.account, stat: false, msg: ''}})
            history.goBack()
        }
    }

    const inputHandler = (e) => {
        const {name, value} = e.target
        tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
        inputs.set({...inputs.data, [name] : {...inputs.data[name], value: value, stat: false, msg: ''}})
        if (name==='account') {
            const v = validateAccount(e.target.value);
            if (v==='mobile_num' && e.target.value[0]==='0') {
                inputs.set({...inputs.data, [name] : {...inputs.data[name], value: e.target.value.substring(1), stat: false, msg: ''}})
            }
            va.set(v)
        }
    }

    const resetPassHandler = () => {
        const sainfo = {
            va: va.data,
            default: window.btoa(`unauth_${inputs.data.account.value}`),
            rrf: 'switching1',
            srf: srf.data
        }

        history.push(`/${platform}/reset/password?sainfo=${encodeURIComponent(JSON.stringify(sainfo))}`)
    }

    const cancelHandler = () => {
        history.push(`/${platform}/login${q.get('from')!==null?`?from=${encodeURIComponent(JSON.stringify(__SESSION))}`:''}`)
    }

    const enterHandler = (event) => { return event.key === 'Enter' ? next() : ''}
    const next = async () => {
        if (step.data!==10 && step.data!==20) {
            tpinputs.set({google: {stat: false, msg: ""}, facebook: {stat: false, msg: ""}})
            inputs.set({password: {...inputs.data.password, stat: false, msg: ''}, account: {...inputs.data.account, stat: false, msg: ''}})
            if (step.data===1) {
                step.set(10)
                if (inputs.data.account.value !== '') {
                    let attempt = await rqx.get(`<<tool>>/${env()}/${platform}/si/attempt/${va.data}`, {account: inputs.data.account.value, ref: 1, platform: platform})
                    if (attempt.msg === 'valid') {
                        step.set(2)
                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: false, msg: ''}})
                    } else {
                        step.set(1)
                        inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account does not exist"}})
                    }
                } else {
                    step.set(1)
                    inputs.set({...inputs.data, account: {...inputs.data.account, stat: true, msg: "Account does not exist"}})
                }
            } else {
                step.set(20)
                if (inputs.data.password.value !== '') {
                    const pass = inputs.data.password.value.includes('#') ? encodeURIComponent(inputs.data.password.value): inputs.data.password.value
                    const attempt = await rqx.get(`<<tool>>/${env()}/${platform}/si/attempt/${va.data}`, {account: inputs.data.account.value, password: pass, ref: 2, platform: platform})
                    if (attempt.msg === 'valid') {
                        const aid = window.atob(attempt.ainfo.aid).split('_').pop()
                        const syncattempt = await rqx.post(`<<tool>>/${env()}/${platform}/sync/attempt/${va.data}`, {aid: aid})
                        if (syncattempt.msg === 'success') {
                            let url = srf.data === 'default' ? platform === 'psl' ? domain('biz').url : domain('psl').url : domain(platform).url
                            if (q.get('from') !== null ) {
                                attempt.ainfo.account_type = 3
                                let subdir = `ainfo=${encodeURIComponent(JSON.stringify(attempt.ainfo))}`
                                if (__SESSION.tsa_key === 'TOOL_MARKET_PREVIEW_SSO_RQX') {
                                    subdir += `&market_preview=${__SESSION.tid}&platform=${__SESSION.platform}`
                                } else if (__SESSION.tsa_key === 'MP_MARKET_CHECKOUT_SSO_RQX') {
                                    subdir += `&platform=${__SESSION.platform}&tid=${__SESSION.tid}&pricing=${encodeURIComponent(__SESSION.pricing)}`
                                } else if (__SESSION.tsa_key === 'MP_DIRECT_CHECKOUT_RQX') {
                                    subdir += `&platform=${__SESSION.platform}&tid=${__SESSION.tid}&pricing=${encodeURIComponent(__SESSION.pricing)}&ref=auth&subcb=${__SESSION.subcb}`
                                } else if (__SESSION.tsa_key === 'PFSHP_SSO_RQX') {
                                    subdir += `&platform=${__SESSION.platform}&ref=auth&subcb=${__SESSION.subcb}`
                                } else if (__SESSION.tsa_key === 'PHRC_SSO_RQX') {
                                    subdir += `&platform=${__SESSION.platform}&ref=auth&subcb=${__SESSION.subcb}`
                                } else if (__SESSION.tsa_key === 'PCHT_SSO_RQX') {
                                    subdir += `&platform=${__SESSION.platform}`
                                } else if (__SESSION.tsa_key === 'PFJOB_SSO_RQX') {
                                    subdir += `&platform=${__SESSION.platform}`
                                } else if (__SESSION.tsa_key === 'PFREFERRAL_SSO_RQX') {
                                    subdir += `&platform=${__SESSION.platform}`
                                }
    
                                url = `${__SESSION.cb}?${subdir}`
                            } else {
                                url = `${url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(attempt.ainfo))}`
                            }

                            stat.set({linked: true, allowed: true})
                            setTimeout(() => {
                                window.location.href = url
                            }, 1500);
                        }
                    } else {
                        step.set(2)
                        inputs.set({...inputs.data, password: {...inputs.data.password, stat: true, msg: "Incorrect password."}})
                    }
                } else {
                    step.set(2)
                    inputs.set({...inputs.data, password: {...inputs.data.password, stat: true, msg: "Password is required"}})
                }
            }
        }
    }
    
    return (
        <Suspense fallback="">
            {
                stat.data.allowed !== null ? (
                    stat.data.allowed ? (
                        <Auth classes={classes} platform={platform} {...switching_state} inputHandler={inputHandler} enterHandler={enterHandler} next={next} q={q} __SESSION={__SESSION} animator={animator} resetPassHandler={resetPassHandler} cancelHandler={cancelHandler} backHandler={backHandler}/>
                    ) : (
                        <Box>asd</Box>
                    )
                ) : (
                    <Box height="100vh" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <img src={LLogo} width={50} alt={LLogo} />
                        <Box mt={4}>
                            <SnakeLoader size="1rem" bg="#0070EF" distance="0.3rem" />
                        </Box>
                    </Box>
                )
            }
            <Snackbar anchorOrigin={{ vertical:'top', horizontal:'center' }} open={stat.data.linked} 
                // autoHideDuration={6000} 
                // onClose={handleClose}
            >
                <Alert variant="filled" severity="success">Account successfully linked</Alert>
            </Snackbar>
        </Suspense>
    )
}


// let sainfo = {
//     account: window.btoa(`unauth_${email__or__mobile_num__ng__nka__login}`),
//     va: email__or__mobile_num,
//     step: 1,
//     srf: '!default'
// }
// window.location.href = `${url__ng__pofmain}/${psl__or__biz}/switching/option1?sainfo=${encodeURIComponent(JSON.stringify(sainfo))}`