import { Container, ButtonBase } from "@mui/material"
import { Box } from "@mui/system"
import { Navigation } from "./../../Navigation"
import { makeStyles } from '@mui/styles';
import { VM } from "../../../../core/VM";
import { useLocation } from "react-router-dom";
import { domain } from "../../../../core/Env";
import { ArrowHeadRight, Bag, Suitcases } from "../../../../core/global/Icons";
import NavigationV2 from "../../../layouts/navigations_v2/Index";

let view = VM()

const style = makeStyles(theme => ({
    button: {
        borderRadius: '8px'    
    },
    buttonws: { 
        borderRadius: '8px',
    },
    vidFrame: {
        borderRadius: '8px',
        width: '80vw',
        height: 'auto',
        position: 'relative',
        paddingBottom: '56.25%',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '92vw',
            height: 'auto',
            position: 'relative',
            paddingBottom: '56.25%',
            overflow: 'hidden',
            paddingLeft: '13px',
            paddingRight: '13px'
        }
    },
}));

export const HomeTablet = () => {
    const q = new URLSearchParams(useLocation().search);
    const classes = style();

    return (
        <Box height="100%">
            <Container sx={{height: '100%'}} >
                <Box display="flex" flexDirection="column" alignItems="center" height="100%" >
                    <Navigation q={q} />
                    <Box mb={3} p={1} fontSize={view ==='/devices/mobile'?'28px':'48px'} fontWeight={view ==='/devices/mobile'?'700':'500'}>GROW YOUR CAREER & BUSINESS</Box>
                    <Box mb={4} fontSize={view ==='/devices/mobile'?'16px':'28px'} fontWeight={view ==='/devices/mobile'?'600':'700'}>10x FASTER, 10x HIGHER & 10x BIGGER!</Box>
                    <Box borderRadius="100px" py={.5} px={1.5} mb={1.5} bgcolor="#B0D8E1" fontSize="11px" fontWeight="600" align="center" lineHeight="120%">"Inspiring stories of extraordinary and heroic individuals in business and careers."</Box>
                    <Box pb="32px">
                        <Box className={classes.vidFrame}>
                            <iframe 
                                src="https://www.youtube.com/embed/JFVrXP1yz7M?autoplay=1" 
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen 
                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                            ></iframe>
                        </Box>
                    </Box>
                    <Box width="100%" display="flex" flexDirection="column" justifyContent="center" gap={3} alignItems="center" color="#292C2F" mb={9.5} maxWidth="460px"> 
                        <a href={`${domain('psl').ws}`}>
                            <Box component={ButtonBase} p={1.5} bgcolor="#FFF" className='c-pointer' sx={{textAlign: 'start', '&:hover': {backgroundColor: '#E8ECEE', '& > div:first-of-child': {bgcolor: '#C9D6DF',},},}} display="flex" justifyContent="space-between" alignItems="center" borderRadius="6px" boxShadow='0px 2px 4px 0px rgba(40, 55, 69, 0.08), 0px 0px 6px 0px rgba(40, 55, 69, 0.02)' width="100%">
                                <Box p={1.5} display="flex" borderRadius="4px" bgcolor="#E3F2FD" mr={2}><Bag /></Box>
                                <Box mr={1.25}>
                                    <Box fontSize={14} fontWeight={600} mb={.5}>Launch your Career Potential</Box>
                                    <Box fontSize={12}>Boost your productivity and personal growth with our all-in-one tools.</Box>
                                </Box>
                                <Box><ArrowHeadRight /></Box>
                            </Box>
                        </a>
                        <a href={`${domain('biz').ws}`}>
                            <Box component={ButtonBase} p={1.5} bgcolor="#FFF" className='c-pointer' sx={{textAlign: 'start', '&:hover': {backgroundColor: '#E8ECEE', '& > div:first-of-child': {bgcolor: '#C9D6DF',},},}} display="flex" justifyContent="space-between" alignItems="center" borderRadius="6px" boxShadow='0px 2px 4px 0px rgba(40, 55, 69, 0.08), 0px 0px 6px 0px rgba(40, 55, 69, 0.02)' width="100%">
                                <Box p={1.5} display="flex" borderRadius="4px" bgcolor="#DAE8DA" mr={2}><Suitcases /></Box>
                                <Box mr={1.25}>
                                    <Box fontSize={14} fontWeight={600} mb={.5}>Empower your Business</Box>
                                    <Box fontSize={12}>Simplify project & tasks management, streamline administration and unlock growth.</Box>
                                </Box>
                                <Box><ArrowHeadRight /></Box>
                            </Box>
                        </a>
                    </Box>
                </Box>
            </Container>
            <NavigationV2 side={1} />
        </Box>
    )
}